<template>
  <div class="examinationdata" ref="examinationdata">
    <div class="minw">
      <el-tabs type="border-card" @tab-click="handClick" v-model="tabActive" v-if="detailFlag">
        <el-tab-pane v-for="(item, index) in tabArr" :key="index" :label="item.dictLabel" :name="item.dictValue"
          v-loading="loading">
          <img src="../../assets/imgs/nodata.png" v-if="list.length == 0" style="display: block; margin: 0 auto"
            alt="" />
          <div style="background: white" v-else>
            <div class="item" v-for="(item, index) in list" :key="index" @click="showDetail(item)">
              <div class="left">
                <!-- <p>{{ item.releaseTime.substr(8, 2) }}</p> -->
                <!-- <p>{{ item.releaseTime.substr(0, 7) }}</p> -->
                <p>{{ item.releaseTime.substr(0, 10) }}</p>
              </div>
              <div class="right">
                <p class="title" :style="{ color: index == 0 ? '#30C9B2' : '' }">
                  {{ item.questionTitle }}
                </p>
                <p style="line-height: 26px;word-break: break-all;text-overflow: ellipsis;overflow: hidden;display: -webkit-box;    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
">{{ item.detailedIntroductionText }}</p>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <pagination v-if="detailFlag" v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize" @pagination="getList" />
      <div class="detail" v-if="!detailFlag">
        <el-button @click="detailFlag = true" style="margin-left:auto;display: block;">返回</el-button>
        <h1 class="title">{{ item.questionTitle }}</h1>
        <p style="text-align:center;margin:15px;color:rgb(147, 147, 147)">
          <span style="margin:0 15px">来源：{{ item.publisher }}</span><span>发布时间：{{ item.releaseTime }}</span>
        </p>
        <div class="ql-container ql-snow">
          <div class="ql-editor" v-html="item.detailedIntroduction">
          </div>
        </div>
      </div>
    </div>
    <div v-for="(item, index) in ceshidata" :key="index" @click="goDetail(index)">
      <img :src="item.img">
      <span class="content">
        <span class="sanjiao" :style="index != 2 ? 'width: 72px' : 'width: 60px'">{{ item.title }}</span>
        <span style="font-weight: 500;font-size: 13px;">{{ item.contents }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import { REGInformationList } from "@/api/collegeinformation.js";
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "@/assets/css/font.css"

export default {
  name: "examinationdata",
  components: {},
  props: {},
  data() {
    return {
      detailFlag: true,
      tabActive: "",
      tabArr: [],
      loading: true,
      list: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        dataType: "",
      },
      item: {},
      bigImgHref: '',
      ceshidata: []
    };
  },
  computed: {},
  watch: {},
  created() {
    const self = this;
    self.loading = true;
    this.getDicts("sys_data_type").then((res) => {
      if (res.code == 200) {
        self.tabArr = res.data;
        self.tabActive = res.data[0].dictValue;
        self.queryParams.dataType = self.tabActive;
        this.getList();
      }
    });

  },
  mounted() {
    //监听浏览器返回
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goPageBefore, false);
    }
    const channel = new BroadcastChannel('share')
    channel.addEventListener('message', (e) => {

      this.ceshidata = e.data
    })
  },
  methods: {
    // 监听浏览器物理返回键
    goPageBefore() {
      if (this.detailFlag) {
        this.$router.push("/");
      } else {
        this.detailFlag = true;
        var state = {
          title: "title",
          url: "#" + this.$route.path
        };
        window.history.pushState(state, "title", "#" + this.$route.path);
      }
    },
    showDetail(item) {
      // 必须要将类名加上，不然出不来video的样式效果
      item.detailedIntroduction = item.detailedIntroduction.replace('ql-video', "ql-video video-js vjs-default-skin vjs-big-play-centered vjs-v7 vjs-user-active")
      this.item = item
      this.detailFlag = false;
              this.$nextTick(() => {
              this.player = videojs(document.getElementsByClassName('ql-video')[0])
               var r = '<video[^<>]*? src="([^<>]*?)"'; // 设置匹配的正则
              var yyy = this.item.detailedIntroduction.match(r);   // data里的数据进行匹配

              this.player.autoplay(false)
              //初始化视频播放器
              this.player.playlist(
                [
                  {
                    name: 'trailer',//视频标题
                    description: 'Explore the depths of our planet\'s oceans.',//视频描述
                    duration: 52,//视频总时长，单位秒(s)
                    sources: [{//视频资源地址以及视频的MIME类型
                      src: yyy[1],
                      type: 'application/x-mpegurl'
                    }]
                  },]);
              this.player.playlistUi();

            })
    },
    getList() {
      const self = this;
      self.loading = true;
      REGInformationList(this.queryParams).then((res) => {
        if (res.code == 200) {
          res.rows.forEach((item) => {
            item.detailedIntroductionText = item.detailedIntroduction ? item.detailedIntroduction.replace(/<.*?>/g, "") : ''
          });
          self.list = res.rows;
          self.total = res.total;
          self.loading = false;

        }
      });
    },
    handClick() {
      this.queryParams.dataType = this.tabActive;
      this.queryParams.pageNum = 1;
      this.queryParams.pageSize = 10;
      this.getList();
    },
  },
  destroyed() {
    window.removeEventListener("popstate", this.goPageBefore, false);
  },

};
</script>

<style scoped lang="scss">
.examinationdata {
  box-sizing: border-box;
  width: 100%;
  background-color: #fcfbf9;
  padding: 30px 0 80px 0;

  .item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0px;


    .left {
      // flex: 1;
      width: 8%;
      text-align: center;
      color: #30C9B2;
      border-radius: 10px;
      box-shadow: 0px 0px 3px #30C9B2;
      margin: 0 10px;
      height: 80px;
      line-height: 70px;
      padding: 0 10px;

      p:nth-of-type(1) {
        font-size: 15px;
        margin: 5px 0;
        // margin-left: 35px;
        // // width: 30px;
        // height: 10px;
      }

      p:nth-of-type(2) {
        font-size: 16px;

      }
    }

    .right {
      flex: 7;
      padding: 10px;
      font-size: 15px;

      .title {
        font-weight: 900;
        font-size: 17px;
        margin: 0 0 10px 0;
        cursor: pointer;
      }

      .title:hover {
        color: #30C9B2;
      }
    }
  }

  .detail {
    box-sizing: border-box;
    background: white;
    padding: 30px 30px 80px 30px;

    .title {
      text-align: center;
      font-size: 25px;
      font-weight: 900;
    }
  }


}






</style>
