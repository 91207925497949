import request from '@/utils/request'


//  新增高考资料 /dev/api/system/gkInformation/add
export const REGInformationAdd = (query) => {
  return request({
    method: 'post',
    url: '/system/gkInformation/add',
    data: query
  })
}

//  编辑高考资料
export const REGInformationEdit = (query) => {
  return request({
    method: 'post',
    url: '/system/gkInformation/edit',
    data: query
  })
}
//查询list
export const REGInformationList = (query) => {
  return request({
    method: 'get',
    url: '/system/gkInformation/queryByDataType',
    params: query
  })
}

// 资讯 查询

export function getInforList() {
  return request({
    url: '/system/information/queryInformation',
    method: 'get'
  })
}

// 资讯详情
export function queryByInfoId(query) {
  return request({
    url: '/system/information/queryByInfoId',
    method: 'get',
    params: query

  })
}
